<template>
  <validation-observer ref="observer">
    <v-form @submit.prevent="submitCb" lazy-validation autocomplete="off">
      <v-card class="form-card">
        <v-card-text class="py-4">
          <div
            class="d-flex align-center justify-space-between px-3 py-3 rounded mt-2 mb-5 drform-tab-text"
          >
            <span class="ml-5 text-333">
              {{ infoModal.tab_text }}
            </span>
            <v-icon
              small
              role="button"
              @click="helpModal(infoModal.component)"
              class="ml-1"
              >$infoIconGrey</v-icon
            >
          </div>

          <div class="d-flex justify-end my-2 full-width">
            <span
              v-if="checkReported()"
              class="rounded text-333 px-2 line-height-16 label-red"
              >未報告日報</span
            >
            <span
              v-else
              class="rounded text-333 px-2 line-height-16 label-green"
              >報告済日報</span
            >
          </div>

          <v-row class="ma-0 px-7">
            <v-col
              v-if="getShopAuthUser[0].user_role == 'S'"
              cols="11"
              class="pa-0"
            >
              <validation-provider
                tag="div"
                v-slot="{ errors }"
                rules="required"
                name="shared_accounts_id"
              >
                <v-label>報告者 </v-label>
                <v-select
                  solo
                  dense
                  background-color="#EAEDF0"
                  :items="user.shared_accounts"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  placeholder="選択してください"
                  class="mt-2 font-14px"
                  v-model="selectedSAccountId"
                  name="shared_accounts_id"
                  no-data-text="メンバーが追加されていません"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col v-else class="pa-0 mb-3 text-333"
              >報告者： {{ `${user.surname} ${user.name}` }}</v-col
            >
          </v-row>

          <div class="custom-flex px-7">
            <div
              v-for="field in fields"
              :key="field.name"
              :style="field.width ? `width: ${field.width}` : 'width: 65%'"
            >
              <template
                v-if="field.type == 'currency' || field.type == 'number'"
              >
                <validation-provider
                  tag="div"
                  v-slot="{ errors }"
                  :rules="field.rules"
                  :name="field.label"
                >
                  <div
                    class="custom-label text-333 font-14px"
                    v-html="field.label"
                  ></div>
                  <template v-if="field.type == 'number'">
                    <InputNumber
                      :placeholder="field.placeholder"
                      background-color="#EAEDF0"
                      dense
                      solo
                      type="number"
                      v-model.number="field.value"
                      :error-messages="errors"
                      hide-details
                      autocomplete="chrome-off"
                      :hint="field.hint"
                      persistent-hint
                      class="dr-field mt-2"
                      :append="field.append"
                    >
                    </InputNumber>
                  </template>

                  <template v-if="field.type == 'currency'">
                    <InputCurrency
                      :placeholder="field.placeholder"
                      background-color="#EAEDF0"
                      dense
                      solo
                      type="number"
                      v-model.number="field.value"
                      :error-messages="errors"
                      hide-details
                      autocomplete="chrome-off"
                      :hint="field.hint"
                      persistent-hint
                      class="dr-field mt-2"
                      :append="field.append"
                    >
                    </InputCurrency>
                  </template>
                  <div class="v-text-field__details">
                    <div
                      class="v-messages theme--light error--text"
                      role="alert"
                    >
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message" v-if="errors[0]">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </validation-provider>
              </template>

              <template v-if="field.type == 'btnGroup'">
                <v-label>
                  <span
                    class="custom-label text-333 font-14px"
                    v-html="field.label"
                  >
                  </span
                ></v-label>
                <v-btn-toggle
                  v-model="field.value"
                  mandatory
                  class="full-width justify-space-between"
                  active-class="dr-btn-active"
                  group
                >
                  <v-btn
                    v-for="(btn, i) in btnGroup"
                    :key="i"
                    height="30px"
                    width="80px"
                    class="font-12px mr-1 dr-btn"
                    :class="!btn.value ? 'd-none' : ''"
                    text
                    color="#bdbdbd"
                    :value="btn.value"
                  >
                    <v-icon small class="mr-1 btn-grp-icon">{{
                      btn.icon
                    }}</v-icon>
                    <span> {{ btn.text }}</span>
                  </v-btn>
                </v-btn-toggle>
              </template>

              <template v-if="field.type == 'textarea'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="pa-0"
                  :class="field.col_class"
                >
                  <validation-provider
                    tag="div"
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-expansion-panels flat accordion>
                      <v-expansion-panel aria-expanded="true">
                        <v-expansion-panel-header class="px-0 font-14px">
                          {{ field.label }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="expansion-content">
                          <v-textarea
                            :placeholder="field.placeholder"
                            background-color="#EAEDF0"
                            dense
                            solo
                            v-model="field.value"
                            :error-messages="errors"
                            hide-details
                            :class="field.class"
                            class="font-15px"
                            rows="5"
                            auto-grow
                          ></v-textarea>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </validation-provider>
                </v-col>
              </template>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-5">
        <v-card-text class="ma-0 px-10 pb-1 pt-4">
          <validation-provider
            tag="div"
            v-slot="{ errors }"
            rules="required:現金売上|enter_half_width_amount"
            name="cash sales"
          >
            <v-label>
              <span class="custom-label text-333 font-14px">
                現金売上
              </span></v-label
            >
            <input-currency
              placeholder="￥0"
              background-color="#EAEDF0"
              dense
              solo
              :error-messages="errors"
              hide-details
              autocomplete="chrome-off"
              persistent-hint
              class="dr-field mt-2"
              :custom="true"
              v-model="cashSaleInput"
              type="number"
            >
            </input-currency>

            <div class="v-text-field__details">
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message" v-if="errors[0]">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </div>
          </validation-provider></v-card-text
        >
      </v-card>

      <v-row class="ma-0 mb-10 mt-6">
        <v-col
          class="px-5 pt-0 pb-0 d-flex justify-space-between"
          cols="12"
          md="12"
        >
          <v-btn
            type="button"
            color="white"
            class="primary--text border-primary"
            min-width="146"
            @click.stop="deleteConfirmation"
            v-if="isEdit"
          >
            削除する
          </v-btn>
          <v-btn
            v-else
            type="button"
            color="white"
            class="primary--text border-primary mr-5"
            min-width="146"
            @click.stop="cancelConfirmation"
          >
            キャンセル
          </v-btn>
          <v-btn type="submit" color="primary" min-width="146">
            保存する
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import InputNumber from "@/components/ui/InputNumber";
import InputCurrency from "@/components/ui/InputCurrency";
import { mapGetters } from "vuex";
export default {
  name: "DrForm",
  components: { InputNumber, InputCurrency },
  props: {
    fields: Array,
    submitCb: Function,
    infoModal: [String, Object],
    cashsale: [String, Number],
    sharedAccountId: [String, Number],
    isReported: [String, Number],
    isEdit: Boolean,
    deleteBtn: { type: Boolean, default: false },
    deleteCb: {
      required: false,
      type: Function,
      default: () => {
        return null;
      }
    },
    dropdownVisible: { type: Boolean, default: false }
  },
  data() {
    return {
      errors: "",
      cashSale: 0,
      btnGroup: [
        { icon: "$sun", text: "晴れ", value: null },
        { icon: "$sun", text: "晴れ", value: 1 },
        { icon: "$cloud", text: "曇り", value: 2 },
        { icon: "$umbrella", text: "雨", value: 3 }
      ]
    };
  },
  computed: {
    ...mapGetters(["user", "getShopAuthUser"]),
    cashSaleInput: {
      get: function() {
        return this.cashsale;
      },
      set: function(newValue) {
        this.$emit("update:cashsale", newValue > 0 ? parseInt(newValue) : 0);
      }
    },
    selectedSAccountId: {
      get: function() {
        return this.sharedAccountId;
      },
      set: function(newValue) {
        this.$emit(
          "update:sharedAccountId",
          newValue > 0 ? parseInt(newValue) : 0
        );
      }
    },
    getShop() {
      return this.$store.getters.getFirstShopObject;
    }
  },
  methods: {
    helpModal(component) {
      this.$store.commit("showModal", { component });
    },
    checkReported() {
      if (this.isReported || this.isReported === "1") {
        return false;
      }
      return true;
    },
    cancelConfirmation() {
      this.$root.confirm
        .open(`編集した内容は破棄されますが\nよろしいですか？`, {
          cancelLable: `いいえ`,
          actionLable: `はい`
        })
        .then(res => {
          if (res) {
            this.$router.push({ name: "drList" });
          }
        });
    },

    deleteConfirmation() {
      this.$root.confirm
        .open(`この日報を削除してもよろしいですか？`)
        .then(res => {
          if (res) {
            let params = {};
            params.shop_id = this.getShop.id;
            params.date = this.$route.params.date;
            this.$store
              .dispatch("DAILY_REPORT_DELETE", params)
              .then(response => {
                if (response.status === 200) {
                  this.$router.push({ name: "drList" });
                  this.$store.dispatch("SHOW_ALERT", {
                    text: "日報を削除しました。",
                    successStatus: "info"
                  });
                }
              });
          }
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>

<style lang="scss">
.dr-field input {
  text-align: center !important;
  font-size: 14px !important;
}

.dr-field .v-input__append-outer {
  margin-top: 10px !important;
}

.custom-flex {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.v-application.main-app-container {
  .v-input__append-outer {
    color: rgba(0, 0, 0, 0.87);
  }
  .error--text {
    .v-input__append-outer {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.dr-btn-active {
  color: white !important;
}
.dr-btn-active::before {
  background-color: #0094ff !important;
  opacity: unset !important;
}
.dr-btn {
  border-radius: 5px !important;
  border: thin solid currentColor !important;
}
.btn-grp-icon svg path {
  fill: currentColor !important;
}
.form-card {
  border-radius: 0px 0px 5px 5px !important;
  border-top: none !important;
}
.drform-tab-text {
  background-color: #e9f0ff;
}
</style>
