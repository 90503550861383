<template>
  <v-text-field
    v-model="_value"
    min="0"
    inputmode="numeric"
    type="number"
    :class="'custom-number-input'"
    :height="height"
    @keypress="isNumberKey($event)"
    @keydown.48="handleZero($event)"
    @keydown.96="handleZero($event)"
    @keydown.delete="handleDelete($event)"
    v-bind="$attrs"
  >
    <template v-if="append" v-slot:append-outer>
      <span>{{ append }}</span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "InputNumber",
  props: {
    value: {
      default: "",
      required: true
    },
    max: {
      type: Number,
      default: 10000000,
      required: false
    },
    color: {
      type: String,
      default: "primary",
      required: false
    },
    height: {
      type: Number,
      default: 20,
      required: false
    },
    custom: {
      type: Boolean,
      default: false,
      required: false
    },
    append: {
      required: false
    }
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    handleDelete(event) {
      if (this._value === 0) event.preventDefault();
    },
    handleZero(event) {
      if (this._value === 0) event.preventDefault();
    },
    isNumberKey: function(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57 || keyCode == 46) {
        event.preventDefault();
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  box-shadow: inset 0 2px 5px rgba(0 0 0 / 10%) !important;
  border-radius: 5px;
}
</style>
